<input
  type="file"
  accept="{{ accept() }}"
  (change)="onFileSelected($event)"
  #fileUpload
/>
<div>
  @if(config().getTypeIndex() === 0) {
  <button
    mat-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    {{ config().getLabel() }}
  </button>

  } @if(config().getTypeIndex() === 1) {
  <button
    mat-raised-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    {{ config().getLabel() }}
  </button>

  } @if(config().getTypeIndex() === 2) {
  <button
    mat-flat-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    {{ config().getLabel() }}
  </button>

  } @if(config().getTypeIndex() === 3) {
  <button
    mat-stroked-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    {{ config().getLabel() }}
  </button>

  }
</div>
